/**
 * @generated SignedSource<<2cfd645f40662751426cf20dbd270374>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppUserPermissionsQuery$variables = Record<PropertyKey, never>;
export type AppUserPermissionsQuery$data = {
  readonly userPermissionsMap: {
    readonly " $fragmentSpreads": FragmentRefs<"App_data">;
  };
};
export type AppUserPermissionsQuery = {
  response: AppUserPermissionsQuery$data;
  variables: AppUserPermissionsQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppUserPermissionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PermissionsMap",
        "kind": "LinkedField",
        "name": "userPermissionsMap",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "App_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppUserPermissionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PermissionsMap",
        "kind": "LinkedField",
        "name": "userPermissionsMap",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "homeResource",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissionsMap",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eecc1bae57618931134b6e2bbef3e1a3",
    "id": null,
    "metadata": {},
    "name": "AppUserPermissionsQuery",
    "operationKind": "query",
    "text": "query AppUserPermissionsQuery {\n  userPermissionsMap {\n    ...App_data\n    id\n  }\n}\n\nfragment App_data on PermissionsMap {\n  id\n  homeResource\n  permissionsMap\n}\n"
  }
};

(node as any).hash = "d5497a63dab732c57298639618f3a36d";

export default node;
