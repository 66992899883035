/**
 * @generated SignedSource<<9399d38921ea5e4ae317b8df4466feb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResourcePaginatedList_data$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly description: string | null | undefined;
      readonly id: string;
      readonly name: string;
    };
  }>;
  readonly pageInfo: {
    readonly endCursor: string | null | undefined;
    readonly startCursor: string | null | undefined;
    readonly total: number | null | undefined;
  };
  readonly " $fragmentType": "ResourcePaginatedList_data";
};
export type ResourcePaginatedList_data$key = {
  readonly " $data"?: ResourcePaginatedList_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResourcePaginatedList_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResourcePaginatedList_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startCursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endCursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Resource",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ResourceConnection",
  "abstractKey": null
};

(node as any).hash = "a9f02fa12b4de93f2acc5d329f0a40d1";

export default node;
