/**
 * @generated SignedSource<<1b28ed76e83c36122d3928a08cd54d9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type App_data$data = {
  readonly homeResource: string | null | undefined;
  readonly id: string;
  readonly permissionsMap: any;
  readonly " $fragmentType": "App_data";
};
export type App_data$key = {
  readonly " $data"?: App_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"App_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "App_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "homeResource",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissionsMap",
      "storageKey": null
    }
  ],
  "type": "PermissionsMap",
  "abstractKey": null
};

(node as any).hash = "b7513e39fc6815b77b62869b630acc0c";

export default node;
