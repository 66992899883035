/**
 * @generated SignedSource<<cd2c9083bc3652a588f5ea53ac9ab273>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DecimalSeparator = "Comma" | "Point" | "%future added value";
export type ThousandSeparator = "Comma" | "Point" | "Prime" | "Space" | "%future added value";
export type AppWrapperQuery$variables = Record<PropertyKey, never>;
export type AppWrapperQuery$data = {
  readonly user: {
    readonly active: boolean;
    readonly customFormat: {
      readonly currency: {
        readonly currency: string | null | undefined;
        readonly decimalSeparator: DecimalSeparator | null | undefined;
        readonly grouping: boolean | null | undefined;
        readonly thousandSeparator: ThousandSeparator | null | undefined;
      } | null | undefined;
      readonly date: {
        readonly format: string | null | undefined;
      } | null | undefined;
      readonly dateTime: {
        readonly format: string | null | undefined;
      } | null | undefined;
      readonly numbers: {
        readonly decimalSeparator: DecimalSeparator | null | undefined;
        readonly grouping: boolean | null | undefined;
        readonly thousandSeparator: ThousandSeparator | null | undefined;
      } | null | undefined;
      readonly time: {
        readonly format: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly imageUrl: string | null | undefined;
    readonly lastName: string;
    readonly locale: string | null | undefined;
    readonly metadata: any | null | undefined;
    readonly phone: string | null | undefined;
    readonly roles: ReadonlyArray<{
      readonly consumer: {
        readonly active: boolean;
        readonly company: {
          readonly id: string;
          readonly name: string;
        } | null | undefined;
        readonly companyId: number | null | undefined;
        readonly customFormat: {
          readonly currency: {
            readonly currency: string | null | undefined;
            readonly decimalSeparator: DecimalSeparator | null | undefined;
            readonly grouping: boolean | null | undefined;
            readonly thousandSeparator: ThousandSeparator | null | undefined;
          } | null | undefined;
          readonly date: {
            readonly format: string | null | undefined;
          } | null | undefined;
          readonly dateTime: {
            readonly format: string | null | undefined;
          } | null | undefined;
          readonly numbers: {
            readonly decimalSeparator: DecimalSeparator | null | undefined;
            readonly grouping: boolean | null | undefined;
            readonly thousandSeparator: ThousandSeparator | null | undefined;
          } | null | undefined;
          readonly time: {
            readonly format: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly deletedAt: any | null | undefined;
        readonly description: string | null | undefined;
        readonly id: string;
        readonly locale: string | null | undefined;
        readonly name: string;
        readonly timezone: string | null | undefined;
      } | null | undefined;
      readonly id: string;
    }> | null | undefined;
    readonly timezone: string | null | undefined;
  };
};
export type AppWrapperQuery = {
  response: AppWrapperQuery$data;
  variables: AppWrapperQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locale",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decimalSeparator",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grouping",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thousandSeparator",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "format",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomFormat",
  "kind": "LinkedField",
  "name": "customFormat",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrencyFormat",
      "kind": "LinkedField",
      "name": "currency",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DateTimeFormat",
      "kind": "LinkedField",
      "name": "date",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DateTimeFormat",
      "kind": "LinkedField",
      "name": "dateTime",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NumberFormat",
      "kind": "LinkedField",
      "name": "numbers",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v4/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DateTimeFormat",
      "kind": "LinkedField",
      "name": "time",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadata",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageUrl",
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRole",
        "kind": "LinkedField",
        "name": "roles",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Consumer",
            "kind": "LinkedField",
            "name": "consumer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyId",
                "storageKey": null
              },
              (v1/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppWrapperQuery",
    "selections": (v10/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppWrapperQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "7e61ae503884aa828ba224ce0dca8225",
    "id": null,
    "metadata": {},
    "name": "AppWrapperQuery",
    "operationKind": "query",
    "text": "query AppWrapperQuery {\n  user {\n    id\n    locale\n    firstName\n    lastName\n    email\n    phone\n    metadata\n    active\n    imageUrl\n    customFormat {\n      currency {\n        currency\n        decimalSeparator\n        grouping\n        thousandSeparator\n      }\n      date {\n        format\n      }\n      dateTime {\n        format\n      }\n      numbers {\n        thousandSeparator\n        grouping\n        decimalSeparator\n      }\n      time {\n        format\n      }\n    }\n    timezone\n    roles {\n      id\n      consumer {\n        id\n        name\n        description\n        active\n        companyId\n        locale\n        customFormat {\n          currency {\n            currency\n            decimalSeparator\n            grouping\n            thousandSeparator\n          }\n          date {\n            format\n          }\n          dateTime {\n            format\n          }\n          numbers {\n            thousandSeparator\n            grouping\n            decimalSeparator\n          }\n          time {\n            format\n          }\n        }\n        timezone\n        company {\n          id\n          name\n        }\n        deletedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0f8bfda1b03d6d055bd92c17a679c99";

export default node;
