/**
 * @generated SignedSource<<b3649b6ddcd088e71a9fe012616b9e37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppUserPermissionsSubscription$variables = Record<PropertyKey, never>;
export type AppUserPermissionsSubscription$data = {
  readonly userPermissionsMap: {
    readonly " $fragmentSpreads": FragmentRefs<"App_data">;
  };
};
export type AppUserPermissionsSubscription = {
  response: AppUserPermissionsSubscription$data;
  variables: AppUserPermissionsSubscription$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppUserPermissionsSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PermissionsMap",
        "kind": "LinkedField",
        "name": "userPermissionsMap",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "App_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppUserPermissionsSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PermissionsMap",
        "kind": "LinkedField",
        "name": "userPermissionsMap",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "homeResource",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissionsMap",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c518a8d335b7398c467c1ad0254bd1a2",
    "id": null,
    "metadata": {},
    "name": "AppUserPermissionsSubscription",
    "operationKind": "subscription",
    "text": "subscription AppUserPermissionsSubscription {\n  userPermissionsMap {\n    ...App_data\n    id\n  }\n}\n\nfragment App_data on PermissionsMap {\n  id\n  homeResource\n  permissionsMap\n}\n"
  }
};

(node as any).hash = "abe7fc39ad48372285a69a3fd8b68731";

export default node;
