/**
 * @generated SignedSource<<b550fabce6969609395c703c5b7ad2ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDirection = "Asc" | "Desc" | "%future added value";
export type CompanyListFilter = {
  alternateNames?: AlternateNamesListFilter | null | undefined;
  companyTypes?: CompanyTypeAssociationsListFilter | null | undefined;
  consumerId?: ReadonlyArray<string> | null | undefined;
  createdAt?: string | null | undefined;
  createdAtRange?: DateRange | null | undefined;
  id?: ReadonlyArray<string> | null | undefined;
  name?: string | null | undefined;
  types?: TypeListFilter | null | undefined;
  updatedAt?: string | null | undefined;
  updatedAtRange?: DateRange | null | undefined;
};
export type AlternateNamesListFilter = {
  id?: ReadonlyArray<string> | null | undefined;
  name?: string | null | undefined;
};
export type TypeListFilter = {
  id?: ReadonlyArray<string> | null | undefined;
  name?: string | null | undefined;
};
export type CompanyTypeAssociationsListFilter = {
  category?: CompanyCategoryListFilter | null | undefined;
  id?: ReadonlyArray<string> | null | undefined;
};
export type CompanyCategoryListFilter = {
  id?: ReadonlyArray<string> | null | undefined;
  name?: string | null | undefined;
};
export type DateRange = {
  end: any;
  start: any;
};
export type CompanyOrderArg = {
  estimatedPeriods?: PositionedOrderDirection | null | undefined;
  id?: PositionedOrderDirection | null | undefined;
  interestRate?: PositionedOrderDirection | null | undefined;
  name?: PositionedOrderDirection | null | undefined;
};
export type PositionedOrderDirection = {
  direction?: OrderDirection | null | undefined;
  position?: number | null | undefined;
};
export type CompanyPaginatedListQuery$variables = {
  after?: string | null | undefined;
  filter: CompanyListFilter;
  first?: number | null | undefined;
  orderBy?: CompanyOrderArg | null | undefined;
};
export type CompanyPaginatedListQuery$data = {
  readonly companies: {
    readonly " $fragmentSpreads": FragmentRefs<"CompanyPaginatedList_data">;
  };
};
export type CompanyPaginatedListQuery = {
  response: CompanyPaginatedListQuery$data;
  variables: CompanyPaginatedListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyPaginatedListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompanyPaginatedList_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompanyPaginatedListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "75d85426226a8c81459849bf63a9e8d8",
    "id": null,
    "metadata": {},
    "name": "CompanyPaginatedListQuery",
    "operationKind": "query",
    "text": "query CompanyPaginatedListQuery(\n  $filter: CompanyListFilter!\n  $orderBy: CompanyOrderArg\n  $first: Int\n  $after: String\n) {\n  companies(filter: $filter, orderBy: $orderBy, first: $first, after: $after) {\n    ...CompanyPaginatedList_data\n  }\n}\n\nfragment CompanyPaginatedList_data on CompanyConnection {\n  pageInfo {\n    startCursor\n    endCursor\n    total\n  }\n  edges {\n    node {\n      id\n      name\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c515e9c82bbd6b5b0aa6c4ef7b6c05e";

export default node;
