/**
 * @generated SignedSource<<11263a3ab879735c9b071ba30f464735>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DecimalSeparator = "Comma" | "Point" | "%future added value";
export type Ownership = "All" | "Consumer" | "User" | "%future added value";
export type Permission = "Allow" | "Deny" | "%future added value";
export type ThousandSeparator = "Comma" | "Point" | "Prime" | "Space" | "%future added value";
export type UpdateUserInput = {
  active?: boolean | null | undefined;
  customFormat?: CustomFormatInput | null | undefined;
  email?: string | null | undefined;
  firstName?: string | null | undefined;
  id: string;
  lastName?: string | null | undefined;
  locale?: string | null | undefined;
  metadata?: any | null | undefined;
  permissions?: ReadonlyArray<EditUserPermissionInput> | null | undefined;
  phone?: string | null | undefined;
  roles?: ReadonlyArray<EditUserRoleInput> | null | undefined;
  saveToGoogleAccount?: boolean | null | undefined;
  timezone?: string | null | undefined;
};
export type CustomFormatInput = {
  currency?: CurrencyFormatInput | null | undefined;
  date?: DateTimeFormatInput | null | undefined;
  dateTime?: DateTimeFormatInput | null | undefined;
  measurement?: MeasurementFormatInput | null | undefined;
  numbers?: NumberFormatInput | null | undefined;
  time?: DateTimeFormatInput | null | undefined;
};
export type DateTimeFormatInput = {
  format?: string | null | undefined;
};
export type NumberFormatInput = {
  decimalSeparator?: DecimalSeparator | null | undefined;
  grouping?: boolean | null | undefined;
  thousandSeparator?: ThousandSeparator | null | undefined;
};
export type CurrencyFormatInput = {
  currency?: string | null | undefined;
  decimalSeparator?: DecimalSeparator | null | undefined;
  grouping?: boolean | null | undefined;
  thousandSeparator?: ThousandSeparator | null | undefined;
};
export type MeasurementFormatInput = {
  decimalSeparator?: DecimalSeparator | null | undefined;
  grouping?: boolean | null | undefined;
  thousandSeparator?: ThousandSeparator | null | undefined;
  unit?: string | null | undefined;
};
export type EditUserRoleInput = {
  consumerId?: string | null | undefined;
  id?: string | null | undefined;
  roleId?: string | null | undefined;
};
export type EditUserPermissionInput = {
  id?: string | null | undefined;
  kind?: Ownership | null | undefined;
  permission?: Permission | null | undefined;
  resourceId?: string | null | undefined;
};
export type UserFormEditMutation$variables = {
  input: UpdateUserInput;
  passwordHash: string;
};
export type UserFormEditMutation$data = {
  readonly updateUser: {
    readonly active: boolean;
    readonly createdAt: any;
    readonly customFormat: {
      readonly currency: {
        readonly currency: string | null | undefined;
        readonly decimalSeparator: DecimalSeparator | null | undefined;
        readonly grouping: boolean | null | undefined;
        readonly thousandSeparator: ThousandSeparator | null | undefined;
      } | null | undefined;
      readonly date: {
        readonly format: string | null | undefined;
      } | null | undefined;
      readonly dateTime: {
        readonly format: string | null | undefined;
      } | null | undefined;
      readonly numbers: {
        readonly decimalSeparator: DecimalSeparator | null | undefined;
        readonly grouping: boolean | null | undefined;
        readonly thousandSeparator: ThousandSeparator | null | undefined;
      } | null | undefined;
      readonly time: {
        readonly format: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
    readonly locale: string | null | undefined;
    readonly permissions: ReadonlyArray<{
      readonly id: string;
      readonly kind: Ownership;
      readonly permission: Permission;
      readonly resource: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    }> | null | undefined;
    readonly phone: string | null | undefined;
    readonly roles: ReadonlyArray<{
      readonly consumer: {
        readonly deletedAt: any | null | undefined;
        readonly id: string;
        readonly name: string;
      } | null | undefined;
      readonly id: string;
      readonly role: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    }> | null | undefined;
    readonly saveToGoogleAccount: boolean | null | undefined;
    readonly timezone: string | null | undefined;
    readonly updatedAt: any;
  } | null | undefined;
};
export type UserFormEditMutation = {
  response: UserFormEditMutation$data;
  variables: UserFormEditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "passwordHash"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decimalSeparator",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grouping",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thousandSeparator",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "format",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v1/*: any*/),
  (v6/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "passwordHash",
        "variableName": "passwordHash"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locale",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomFormat",
        "kind": "LinkedField",
        "name": "customFormat",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrencyFormat",
            "kind": "LinkedField",
            "name": "currency",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DateTimeFormat",
            "kind": "LinkedField",
            "name": "date",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DateTimeFormat",
            "kind": "LinkedField",
            "name": "dateTime",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NumberFormat",
            "kind": "LinkedField",
            "name": "numbers",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DateTimeFormat",
            "kind": "LinkedField",
            "name": "time",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timezone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRole",
        "kind": "LinkedField",
        "name": "roles",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Consumer",
            "kind": "LinkedField",
            "name": "consumer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserPermission",
        "kind": "LinkedField",
        "name": "permissions",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Resource",
            "kind": "LinkedField",
            "name": "resource",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permission",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "saveToGoogleAccount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserFormEditMutation",
    "selections": (v8/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserFormEditMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "0be9b5f428b1e0ad3f4416da726bc48f",
    "id": null,
    "metadata": {},
    "name": "UserFormEditMutation",
    "operationKind": "mutation",
    "text": "mutation UserFormEditMutation(\n  $input: UpdateUserInput!\n  $passwordHash: String!\n) {\n  updateUser(input: $input, passwordHash: $passwordHash) {\n    id\n    email\n    firstName\n    lastName\n    active\n    phone\n    locale\n    customFormat {\n      currency {\n        currency\n        decimalSeparator\n        grouping\n        thousandSeparator\n      }\n      date {\n        format\n      }\n      dateTime {\n        format\n      }\n      numbers {\n        thousandSeparator\n        grouping\n        decimalSeparator\n      }\n      time {\n        format\n      }\n    }\n    timezone\n    roles {\n      id\n      consumer {\n        id\n        name\n        deletedAt\n      }\n      role {\n        id\n        name\n      }\n    }\n    permissions {\n      id\n      resource {\n        id\n        name\n      }\n      kind\n      permission\n    }\n    createdAt\n    updatedAt\n    saveToGoogleAccount\n  }\n}\n"
  }
};
})();

(node as any).hash = "b4fb6e652723569d18d560cd2f7d35e9";

export default node;
