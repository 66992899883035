/**
 * @generated SignedSource<<bfd9e0670d3b11bf36e384ff8e36674b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EmailCheckQuery$variables = {
  email: string;
};
export type EmailCheckQuery$data = {
  readonly isEmailUsed: boolean;
};
export type EmailCheckQuery = {
  response: EmailCheckQuery$data;
  variables: EmailCheckQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "kind": "ScalarField",
    "name": "isEmailUsed",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailCheckQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailCheckQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1debe11d9a7bff31e442aa4ce88f9901",
    "id": null,
    "metadata": {},
    "name": "EmailCheckQuery",
    "operationKind": "query",
    "text": "query EmailCheckQuery(\n  $email: String!\n) {\n  isEmailUsed(email: $email)\n}\n"
  }
};
})();

(node as any).hash = "6cc0f1366fb78b6e00a9a7e7aafdda98";

export default node;
